<!--
 * @Author: wangshuaiwei
 * @Date: 2021-12-14 10:37:01
 * @LastEditors: wangshuaiwei
 * @LastEditTime: 2022-01-25 15:03:53
 * @Description: 
-->
<template>
  <div class="productionManagement">
    <div class="draw-title">
      <el-button
        v-if="saveState == 1"
        class="butStyle"
        size="mini"
        @click="saveNewPlot"
        >新建批次</el-button
      >
      <el-button
        v-if="saveState == 1"
        class="butStyle"
        size="mini"
        @click="eaitNewPlot2"
        >编辑/查看</el-button
      >
      <el-button
        v-if="saveState == 0"
        class="butStyle"
        size="mini"
        @click="saveNewPlot2"
        >保存</el-button
      >
      <!-- <el-button class="butStyle" size="mini" @click="showMsg">查看</el-button> -->
      <el-button class="butStyle" size="mini" @click="removeNewPlot"
        >删除</el-button
      >
    </div>
    <drawMap
      @resetBut="resetBut"
      @eaitState="eaitState"
      @openState="openState"
      @openPopup="openPopup"
      ref="drawMap"
    />
    <el-collapse-transition>
      <crop-model
        :nonBatch="nonBatch"
        :productCycle="productCycle"
        :echoStatus="echoStatus"
        @saveModel="saveFrom"
        @exits="exits"
        :cropsList="cropsList"
        :massifList="massifList"
        :batchWkt="batchWkt"
        ref="modelCom"
        class="modelBox transition-box"
        v-if="modelState"
      />
    </el-collapse-transition>

    <div class="drawList">
      <div class="nameTitle">批次信息</div>
      <div class="scroll">
        <div class="noneList" v-if="drawLists.length == 0">暂无批次</div>
        <div v-else>
          <div class="nameBox" v-for="(item, index) in drawLists" :key="index">
            <div class="bases">
              所在基地：<span>{{ item.plotName }}</span>
            </div>
            <div class="batchs">
              种植批次：<span>{{ item.batchNumber }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import drawMap from "@/components/soureUnit/batchDraws.vue";
import cropModel from "@/components/soureUnit/cropBatchs.vue";
export default {
  components: { drawMap, cropModel },
  data() {
    return {
      saveState: 1,
      cropsList: [],
      massifList: [],
      modelState: false,
      batchWkt: [],
      drawLists: [],
      productCycle: null,
      nonBatch: "add",
      echoStatus: true, //批次驳回状态回显state
      locations: "",
      type: 0, // 区分 新增和编辑
    };
  },

  mounted() {
    this.$get("/productCycle/qurryProductCycle", {
      page: 1,
      size: 100000,
    }).then((res) => {
      if (res.data.state == "success") {
        this.cropsList = res.data.datas;
      }
    });
    this.$get("/enterpriseManage/querryLandPlot", {
      page: 1,
      size: 100000,
    }).then((res) => {
      if (res.data.state == "success") {
        this.massifList = res.data.datas;
      }
    });
    this.getBatchs();
  },
  methods: {
    getBatchs() {
      this.$get("/plantingBatch/getPlantingBatchList", {
        page: 1,
        size: 100000,
      }).then((res) => {
        if (res.data.state == "success") {
          this.drawLists = res.data.datas;
        }
      });
    },
    // 新增
    saveNewPlot() {
      this.type = 0;
      this.nonBatch = "add";
      this.echoStatus = false;
      this.$refs.drawMap.batchModification();
    },
    // 编辑
    eaitNewPlot2() {
      this.type = 1;
      this.nonBatch = "eait";
      this.echoStatus = false;
      this.$refs.drawMap.batchModification2();
    },
    // 点击查看
    showMsg() {
      this.type = 1;
      this.nonBatch = "eait";
      this.echoStatus = false;
      this.$refs.drawMap.batchModification2();
    },
    // 保存
    saveNewPlot2() {
      this.type = 1;
      this.saveState = 1;
      this.modelState = true;
      this.$nextTick(() => {
        this.$refs.modelCom.echoPlantingBatch2();
      });
    },

    /**
     * @description:  修改批次超出范围重置按钮
     * @param {*}
     */
    resetBut() {
      this.saveState = 1;
    },
    // 删除
    removeNewPlot() {
      let data = this.$refs.drawMap.delectFeature;
      if (!data) return this.$message.info("请选择批次进行删除");
      if (data[0].explain != "batch") return this.$message.info("地块不可删除");
      this.$confirm("此操作将永久删除该批次, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$get("/plantingBatch/delPlantingBatch", {
            id: data[0].massifId,
          }).then((res) => {
            //console.log(res);
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              this.$refs.drawMap.batchDelect(data[0]);
              this.$refs.drawMap.delectFeature = null;
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    exits(addAndEait) {
      this.$refs.drawMap.batchPopup(addAndEait);
      this.modelState = false;
    },
    /**
     * @description:  绘制批次信息
     * @param {*} batchWkt 地块 经纬度 name id
     */
    openState(batchWkt) {
      this.batchWkt = batchWkt;
      //console.log(this.batchWkt, "openState-------------");
    },
    openPopup() {
      this.$nextTick(() => {
        this.modelState = true;
      });
    },
    eaitState(item) {
      // debugger;
      let id = "";
      if (item.greenhouseId == null && item.explain == "house" ) {
        id = item.massifId;
      }else if (item.greenhouseId != null && item.explain == "batch"&& item.massifState == 2 ) {
        id = item.massifId;
      }else if(item.greenhouseId != null && item.explain == "batch" && item.massifState == 0){
        id = item.greenhouseId;
      }
      //console.log(this.type);
      if (this.type == 0) {
        this.saveState = 0;
        this.$get("/greenhouses/querryGreenhousesAndPlot", { id }).then(
          (res) => {
            //console.log(res);
            if (res.data.state == "success") {
              this.productCycle = res.data.data;
              this.locations = this.productCycle.greenhousesBean.location;
              //console.log(this.productCycle);
            }
          }
        );
      } else {
        this.saveState = 0;
        this.$get("/plantingBatch/fatchPlantingBatch", { id }).then((res) => {
          //console.log(id);
          if (res.data.state == "success") {
            this.productCycle = res.data.data;
            //console.log(this.productCycle);
          }
        });
      }
    },
    /**
     * @description:
     * @return {*} String     processInfo json
     * @return {*} String     location  经纬度位置
     * @return {*} String     productImg 生长周期图片（最外层）
     * @return {*} String    state     产品状态:0待审核，1申请中，2审核通过，3审核驳回
     * @return {*} Timestamp  plantingTime,	种植时间
     * @return {*} String    plantingArea,   地块面积
     * @return {*} String     plotName,		地块名称
     * @return {*} String    plotName,		地块名称
     * @return {*} String    batchNumber,	批次号
       @return {*} String    productName,	作物名称
       @return {*} String    plantType,		作物类别
       @return {*} long      plotId,		地块id
     */

    saveFrom(data, state) {
      //console.log(this.$refs.drawMap.polygonWkt);
      this.$refs.drawMap.selectedFeature = null;
      let url, location;
      if (state == "add") {
        url = "/plantingBatch/addPlantingBatch";
        location = this.locations;
      } else {
        url = "/plantingBatch/updatePlantingBatch";
        if (this.$refs.drawMap.polygonWkt) {
          location = this.$refs.drawMap.polygonWkt.wkt[0];
          data.id = this.$refs.drawMap.polygonWkt.id;
        }
        delete data.del;
        delete data.createTime;
        delete data.productCycleId;
        if (this.$refs.drawMap.polygonWkt) delete data.location;
      }
      data.processInfo = JSON.stringify(data.processInfo);
      this.$post(url, {
        location,
        plotId: this.batchWkt.id,
        plotName: this.batchWkt.name,
        plantingArea: this.batchWkt.size,
        ...data,
        state: 2,
      }).then((res) => {
        if (res.data.state == "success") {
          this.$message.success("添加或修改批次成功");
          this.modelState = false;
          this.getBatchs();
          this.$refs.drawMap.ruquestBatch();
          this.$refs.drawMap.addBatchSuccess();
        }
        //console.log(res);
      });
    },
  },
};
</script>
<style scoped lang="less">
@import "~@/style/colorPublic";
.butStyle {
  background: @but_color;
  color: #fff;
}
.productionManagement {
  width: 100%;
  height: 100vh;
}
.draw-title {
  position: absolute;
  top: 5vh;
  left: 50%;
  width: 97%;
  height: 7vh;
  background: #fff;
  z-index: 999999;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  line-height: 7vh;
  padding-left: 3vh;
  box-sizing: border-box;
}

.modelBox {
  position: absolute;
  z-index: 1111;
  top: 55%;
  background: #fff;
  left: 50%;
  width: 58vw;
  height: 70vh;
  transform: translate(-50%, -50%);
  padding: 2vh;
  overflow: hidden;
}
.modelBox /deep/ .gowthStageItemBox {
  width: 100%;
  height: 26vh;
}
/deep/ .information {
  height: 31vh;
  overflow: hidden;
}
@media screen and (max-width: 1500px) {
  /deep/ .information {
    height: 19vh;
  }
}
.drawList {
  width: 40vh;
  height: 76vh;
  position: absolute;
  top: 10vh;
  right: 5vh;
  color: rgb(51, 50, 50);
  // background-color: #fff;
  background-color: rgba(255, 255, 255, 0.8);
}
.scroll {
  height: 70vh;
  overflow-y: scroll;
}
.nameTitle {
  text-align: center;
  font-size: 3vh;
  font-family: "楷体";
  font-style: italic;
}
.noneList {
  font-size: 3vh;
  margin-top: 3vh;
  text-align: center;
}
.nameBox {
  width: 90%;
  height: 10.5vh;
  margin: 2vh auto;
  border: 1px solid rgb(151, 151, 151);
  // background-color: pink;
  font-size: 1.6vh;
  overflow: hidden;
}
.bases {
  margin-left: 4vh;
  margin-bottom: 2vh;
  margin-top: 0.8vh;
}
.batchs {
  margin-left: 4vh;
}
.nameBox span {
  margin-left: 1.5vh;
  color: #0049d0;
}
</style>
