<!--
 * @Author: wangshuaiwei
 * @Date: 2021-12-09 19:24:23
 * @LastEditors: wangshuaiwei
 * @LastEditTime: 2022-03-01 15:59:35
 * @Description: 
-->
<template>
  <div class="addMassif">
    <!-- <div class="ol-title">
      <p>{{ $attrs.stateText }}</p>
    </div> -->
    <el-form class="baseFromLable" :rules="rules" ref="addMassif" :model="addMassif" label-width="80px">
      <el-form-item label="基地名称" prop="name">
        <el-input v-model="addMassif.name"></el-input>
      </el-form-item>
      <el-form-item label="经营主体" prop="enterpriseId">
        <el-select v-model="addMassif.enterpriseId" @change="plotAreaChange" placeholder="请选择经营主体名称">
          <el-option v-for="item in nterpriseList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="产业类型" prop="type">
        <el-select v-model="addMassif.type" @change="plotBaseChange" placeholder="请选择产业类型">
          <el-option v-for="item in baseList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="面积信息" prop="enterpriseName">
        <el-input v-model="addMassif.enterpriseName" disabled></el-input>
      </el-form-item>
      <el-form-item label="基地地址" prop="address">
        <el-input v-model="addMassif.address"></el-input>
      </el-form-item>
      <el-form-item label="联系人" prop="linkMan">
        <el-input v-model="addMassif.linkMan"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="phoneNum">
        <el-input v-model="addMassif.phoneNum"></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="addMassif.email"></el-input>
      </el-form-item>
      <el-form-item label="行政区划信息" prop="areaInfo">
        <el-input type="textarea" v-model="addMassif.areaInfo"></el-input>
      </el-form-item>
      <el-form-item class="isUpload" label="基地图片">
        <el-upload class="avatar-uploader" action="/imgUpdata" :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload" :show-file-list="false">
          <img v-if="addMassif.areaImgs" :src="addMassif.areaImgs" class="businessImg  avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon uploadImg"></i>
        </el-upload>
      </el-form-item>
    </el-form>
    <div class="ol-but">
      <el-button size="mini" @click="cancel">取消</el-button>
      <el-button class="butColor" type="primary" @click="determine(addMassif, 'addMassif')" size="mini">确定</el-button>
    </div>
  </div>
</template>
<script>
import { massif } from "@/utils/required.js";
export default {
  data() {
    return {
      addMassif: { enterpriseName: "", areaImgs: "" },
      rules: massif,
      nterpriseList: [],
      tableData: [],
      baseList: [
        {
          id: 1,
          name: '种植业'
        },
        {
          id: 2,
          name: '畜牧业'
        },
      ]
    };
  },
  mounted() {
    this.requestNnterprise();
  },
  methods: {
    // 获取产业类型
    requestNnterprise() {
      this.$get("/enterpriseManage/querryEnterprise", {
        page: 1,
        size: 10000,
      }).then((res) => {
        // //console.log(res);
        if (res.data.state == "success") {
          this.nterpriseList = res.data.datas;
        }
      });
    },
    // 取消按钮
    cancel() {
      this.$emit("cancel");
    },
    // 确定按钮
    determine(value, dom) {
      if (!this.addMassif.areaImgs) return this.$message.info("请上传基地图片");
      this.$refs[dom].validate((valid) => {
        if (valid) this.$emit("determine", value);

      });

    },
    echoList(data) {
      //console.log(this.$attrs.recordData);
      this.addMassif = data.landPlotBean;
    },
    // 上传图片
    beforeAvatarUpload(val) {
      // let { name } = file;
      // name = name.split(".")[1];
      // if (name == "jpg" || name == "png" || name == "jpeg") {
      //   return true;
      // }
      // this.$message.info("请检查上传格式或上传图片不能大于1M");
      // this.fileList2 = [];
      // return false;
      const fileLimitSize = 1;// 几兆限制
      const isJPG = val.type === "image/jpeg";
      const isJPGs = val.type === "image/png";
      const fileLimit = val.size / 1024 / 1024 < fileLimitSize;
      if (!isJPG && !isJPGs) {
        this.$message.info("上传图片格式只能为 JPG 或 PNG 格式！");
        this.fileList2 = [];
        return false
      }
      if (!fileLimit) {
        this.$message.info(`上传图片大小不能超过${fileLimitSize}MB!`);
        this.fileList2 = [];
        return false
      }

      return true;
    },
    handleAvatarSuccess(res) {
      this.addMassif.areaImgs =
        "https://public.half-half.cn/" + res.data.fileName;
    },
    // 基地名称change事件
    plotAreaChange(val) {
      let name = this.nterpriseList.find((v) => val == v.id).name;
      this.addMassif.plotArea = name;
    },
    // 产业类型change事件
    plotBaseChange(val) {
      let name = this.baseList.find((v) => val == v.id).id;
      this.addMassif.type = name;
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/style/colorPublic";

.butColor {
  background: @but_color;
}

.addMassif {
  width: 30vw;
  background: #fff;
  padding: 0 2vh;
  box-sizing: border-box;
}

.ol-title {
  font-size: 1.2vh;
  height: 5vh;
  line-height: 5vh;
  border-bottom: 1px solid #ccc;
  margin-bottom: 2vh;
}

.ol-but {
  margin-bottom: 3vh;
  text-align: right;
}

.uploadImg {
  display: block;
  font-size: 4.5vh;
  display: block;
  border: 1px dashed #ccc;
}

.isUpload .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.isUpload .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.isUpload .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 147px;
  height: 147px;
  line-height: 147px;
  text-align: center;
}

.isUpload .businessImg {
  width: 15vh;
  height: 15vh;
}

@media screen and (max-width: 1500px) {
  .baseFromLable {
    height: 57vh;
    overflow: auto;
  }
}
</style>
