<!--
 * @Author: wangshuaiwei
 * @Date: 2021-12-15 18:56:23
 * @LastEditors: wangshuaiwei
 * @LastEditTime: 2022-03-24 19:13:49
 * @Description:
-->

<template>
  <div class="corpModel">
    <div class="operationButton">
      <el-button size="small" @click="clearList">取消 </el-button>

      <el-button
        type="primary"
        class="butColor"
        @click="saveModel(gowthStage, titleForm)"
        size="small"
        >保存
      </el-button>
    </div>
    <div class="conter">
      <div class="proctImg essentialImg">
        <el-upload
          v-if="!eaitState"
          class="avatar-uploader"
          action="/imgUpdata"
          :show-file-list="false"
          :on-success="plantImghandleSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="plantImg" :src="plantImg" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <img v-else-if="eaitState" :src="plantImg" class="avatar" />
      </div>
      <div class="essential">
        <div>
          <h3>基本信息</h3>
          <div v-if="$attrs['nonBatch'] == 'crops'" class="public titleFormCon">
            <el-form
              ref="titleForm"
              :rules="rules"
              class="titleForm"
              :model="titleForm"
              label-width="80px"
            >
              <el-form-item label="作物名称" prop="productName">
                <el-input
                  v-model="titleForm.productName"
                  :readonly="eaitState"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item label="作物类别" prop="plantType">
                <el-input
                  v-model="titleForm.plantType"
                  :readonly="eaitState"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div
            v-if="$attrs['nonBatch'] == 'eait' || $attrs['nonBatch'] == 'add'"
            class="public titleFormCon"
          >
            <el-form
              ref="titleForm"
              :rules="rules"
              class="batchBox"
              :model="titleForm"
              label-width="80px"
            >
              <el-form-item label="作物名称" prop="productName">
                <el-select
                  v-model="titleForm.productName"
                  @change="cropNameChange"
                  placeholder="请选择作物名称"
                >
                  <el-option
                    v-for="item in $attrs['cropsList']"
                    :key="item.id"
                    :label="item.plantName"
                    :value="String(item.id)"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="作物类别" prop="plantType">
                <el-input
                  v-model="titleForm.plantType"
                  :readonly="eaitState"
                  placeholder="请输入"
                  disabled
                ></el-input>
              </el-form-item>
              <el-form-item label="地块名称" prop="plotName">
                <el-input
                  disabled
                  v-model="titleForm.plotName"
                  :readonly="eaitState"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item label="大棚名称" prop="greenhouseName">
                <el-input
                  disabled
                  v-model="titleForm.greenhouseName"
                  :readonly="eaitState"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item label="种植批次" prop="batchNumber">
                <el-input
                  v-model="titleForm.batchNumber"
                  :readonly="eaitState"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item label="面积信息" prop="plantingArea">
                <el-input
                  disabled
                  v-model="titleForm.plantingArea"
                  :readonly="eaitState"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item label="种植数量" prop="plantingNumber">
                <el-input
                  v-model="titleForm.plantingNumber"
                  :readonly="eaitState"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="种植时间"
                prop="plantingTime"
                class="plantingClassInput"
              >
                <el-date-picker
                  v-model="titleForm.plantingTime"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                  type="date"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="摄像头" prop="cameraIds">
                <el-select
                  disabled
                  v-model="titleForm.cameraIds"
                  @change="cameraChange"
                  :collapse-tags="true"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in cameraList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="气象设备" prop="weatherDeviceId">
                <el-select
                  disabled
                  v-model="titleForm.weatherDeviceId"
                  @change="deviceChange"
                  :collapse-tags="true"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in equipmentList1"
                    :key="item.Nid"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div>
          <div class="grow">
            <h3>生长周期</h3>
          </div>
          <div class="information">
            <div v-for="item in gowthStage" :key="item.id">
              <div class="gowthStageItemBox">
                <div
                  class="gowthStageItem"
                  v-for="(self, index) in item.productCycleModelInfoBeanList"
                  :key="self.id"
                >
                  <button
                    type="button"
                    class="buttons"
                    aria-label="Close"
                    @click="close(item, index)"
                  >
                    <i class="el-dialog__close el-icon el-icon-close"></i>
                  </button>
                  <div class="imgUpload">
                    <el-upload
                      v-if="!eaitState"
                      class="avatar-uploader"
                      action="/imgUpdata"
                      :show-file-list="false"
                      :on-success="(file) => handleSuccess(file, self)"
                      :before-upload="beforeAvatarUpload"
                    >
                      <img v-if="self.url" :src="self.url" class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <img v-else-if="eaitState" :src="self.url" class="avatar" />
                  </div>
                  <div>
                    <el-form
                      class="phase"
                      ref="phaseDetails"
                      :model="phaseDetails"
                    >
                      <el-form-item>
                        <el-input
                          v-model="self.name"
                          :readonly="eaitState"
                          placeholder="请输入作物周期"
                        ></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-input
                          v-model="self.day"
                          :readonly="eaitState"
                          placeholder="请输入"
                          ><i
                            slot="suffix"
                            style="
                              font-style: normal;
                              margin-right: 30px;
                              color: black;
                            "
                            >天</i
                          ></el-input
                        >
                      </el-form-item>
                      <el-form-item>
                        <el-input
                          :readonly="eaitState"
                          v-model="self.rempMin"
                          placeholder="最小值"
                          ><i
                            slot="suffix"
                            style="
                              font-style: normal;
                              margin-right: 30px;
                              color: black;
                            "
                            >℃</i
                          ></el-input
                        >
                        <el-input
                          :readonly="eaitState"
                          v-model="self.rempMax"
                          placeholder="最大值"
                          ><i
                            slot="suffix"
                            style="
                              font-style: normal;
                              margin-right: 30px;
                              color: black;
                            "
                            >℃</i
                          ></el-input
                        >
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
                <div class="addGowthStage">
                  <span @click="addModel(item)" class="el-icon-plus"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { batch } from "@/utils/required.js";

export default {
  data() {
    return {
      titleForm: {
        greenhouseId: "",
        greenhouseName: "",
        plantingArea: "",
        cameraIds: "",
        weatherDeviceId: "",
        soilDeviceId: "",
        batchNumber: "",
        productName: "",
        plantName: "",
        plantType: "",
        plotId: "",
        plotName: "",
        plantingNumber: "",
        plantingTime: "",
      },
      gowthStage: [
        {
          id: 0,
          name: "",
          productCycleModelInfoBeanList: [
            {
              name: "",
              day: "",
              url: "",
              rempMin: "",
              rempMax: "",
            },
          ],
        },
      ],
      plantImg: null,
      phaseDetails: {},
      imageUrl: "",
      eaitState: false,
      address: null,
      productionList: [],
      addAndEait: false,
      cameraList: [], // 摄像头
      equipmentList: [],
      equipmentList1: [], // 气象设备
      equipmentList2: [],
      id1: "",
      id2: "",
      rules: batch,
      ids: 0,
      closes: true,
    };
  },
  mounted() {
    this.requestproduction();
    this.requestCamera();
    this.$nextTick(() => {
      this.$refs.titleForm.resetFields();
      setTimeout(() => {
        if (this.$attrs["echoStatus"]) {
          //console.log(this.$attrs["batchWkt"]);
          this.titleForm.plotName = this.$attrs["batchWkt"].name;
          this.titleForm.plantingArea = this.$attrs["batchWkt"].size;
          this.$forceUpdate();
          this.titleForm.plotId = this.$attrs["batchWkt"].id;
          this.titleForm.massifId = this.$attrs["batchWkt"].id;
        }
      }, 1000);
    });
  },

  methods: {
    //摄像头
    requestCamera() {
      this.$get("/cameraManage/getCameraList", { page: 1, limit: 1000 }).then(
        (res) => {
          if (res.data.state == "success") {
            //console.log(res);
            this.cameraList = res.data.datas;
            //console.log(this.cameraList);
          }
        }
      );
      // 气象设备
      this.$get("/deviceManage/getDeviceLists", {
        page: 1,
        limit: 1000,
        groupId: 1,
      }).then((res) => {
        if (res.data.state == "success") {
          //console.log(res);
          this.equipmentList = res.data.datas;
          this.equipmentList1 = [];
          this.equipmentList2 = [];
          for (let i = 0; i < this.equipmentList.length; i++) {
            if (this.equipmentList[i].deviceType == 1) {
              this.equipmentList1.push(this.equipmentList[i]);
            }
            if (this.equipmentList[i].deviceType == 2) {
              this.equipmentList2.push(this.equipmentList[i]);
            }
          }
          //console.log(this.equipmentList);
        }
      });
    },
    close(item, index) {
      //console.log(item, index);
      item.productCycleModelInfoBeanList.splice(index, 1);
    },
    // 摄像头change事件
    cameraChange(val) {
      //console.log(val);
      this.requestCamera();
      this.titleForm.cameraIds = val;
      //console.log(this.titleForm.cameraIds);
    },
    deviceChange(val) {
      this.id1 = val;
      this.requestCamera();
      this.titleForm.weatherDeviceId = val;
    },
    deviceChange2(val) {
      this.id2 = val;
      this.requestCamera();
      this.titleForm.soilDeviceId = val;
    },
    addModel(item) {
      if (this.eaitState) return;
      item.productCycleModelInfoBeanList.push({
        name: "",
        day: "",
        url: "",
        rempMin: "",
        rempMax: "",
      });
    },
    requestproduction() {
      this.$get("/productCycle/qurryProductCycle", {
        page: 1,
        size: 100000,
      }).then((res) => {
        if (res.data.state == "success") {
          this.productionList = res.data.datas;
        }
      });
    },
    addGrowthStage() {
      if (this.eaitState) return;
      this.gowthStage.push({
        id: this.gowthStage.length + 1,
        name: "生育期",
        productCycleModelInfoBeanList: [
          {
            name: "",
            day: "",
            url: "",
            rempMin: "",
            rempMax: "",
          },
        ],
      });
    },
    handleSuccess(file, self) {
      self.url = "https://public.half-half.cn/" + file.data.fileName;
    },
    plantImghandleSuccess(file) {
      this.plantImg = "https://public.half-half.cn/" + file.data.fileName;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPng = file.type === "image/png";
      if (isJPG || isPng) {
        return true;
      }
      this.$message.info("请检查上传格式！");
      this.fileList = [];
      return false;
    },
    /**
     * @description: 保存
     * @param {*} data
     * @param {*} nonBatch true 生产周期配置   fasle 种植批次
     */
    saveModel(data, base) {
      if (!this.plantImg) this.$message.info("请上传图片");
      this.$refs["titleForm"].validate((valid) => {
        if (valid) {
          if (this.$attrs["nonBatch"] == "crops") {
            let { plantName, plantType, greenhouseId } = this.titleForm;
            let datas = {
              greenhouseId,
              plantName,
              plantType,
              productImg: this.plantImg,
              processInfo: JSON.stringify(data),
            };
            if (this.address == "add") delete datas.id;
            this.$emit("saveModel", datas, this.address);
          } else if (this.$attrs["nonBatch"] == "eait") {
            //修改
            let batch = {
              processInfo: data,
              ...base,
              productImg: this.plantImg,
            };
            //console.log(data);
            //console.log(base);
            this.$emit("saveModel", batch, "eait");
          } else if (this.$attrs["nonBatch"] == "add") {
            //保存
            delete base.id;
            delete base.camera;
            delete base.areaInformation;
            delete base.meteorologicalEquipment;
            delete base.soilEquipment;
            delete base.createUserName;
            delete base.greenhousePhoto;
            let batch = {
              processInfo: data,
              ...base,
              productImg: this.plantImg,
            };
            //console.log(data);
            //console.log(base);
            this.$emit("saveModel", batch, "add");
            // cameraIds: this.cameraIds, deviceIds: this.deviceIds,
          }
        }
      });
    },
    /**
     * @description: 生产周期配置编辑
     * @param {*} val 值
     * @param {*} state 编辑1 查看0
     */
    taitModel(val, state) {
      this.$nextTick(() => {
        // let { plantImg, plantName, plantType, processInfo, id } = val;

        let {
          plantImg,
          plantName,
          plantType,
          productCycleModelBeanList,
          id,
        } = val;
        this.plantImg = plantImg;
        this.titleForm = {
          plantName,
          plantType,
          id,
        };
        // this.gowthStage = JSON.parse(processInfo);productCycleModelInfoBeanList
        this.gowthStage = JSON.parse(JSON.stringify(productCycleModelBeanList));
        //console.log(this.gowthStage);
        if (
          productCycleModelBeanList[0].productCycleModelInfoBeanList.length == 0
        ) {
          this.gowthStage[0].productCycleModelInfoBeanList = [
            {
              name: "",
              day: "",
              url: "",
              rempMin: "",
              rempMax: "",
            },
          ];
        }
        this.address = "eait";
        if (state == 0) this.eaitState = true;
      });
    },
    // 请选择作物名称change
    cropNameChange(id) {
      //console.log(id);
      this.$get("/productCycle/fatchProductCycleById", {
        id,
      }).then((res) => {
        if (res.data.state == "success") {
          let batchModel = res.data.data.productCycle.productCycleModelBeanList;
          // //console.log(batchModel);
          // //console.log(res.data.data.productCycle.productCycleModelBeanList);
          if (
            batchModel &&
            batchModel[0].productCycleModelInfoBeanList.length != 0
          )
            // //console.log(batchModel[0]);
            this.gowthStage = batchModel;
          // //console.log(this.gowthStage);
          this.titleForm.plantType = res.data.data.productCycle.plantType;
          // //console.log(this.titleForm.plantType);
          this.titleForm.plantName = this.$attrs["cropsList"].find(
            (v) => id == v.id
          ).plantName;
          // //console.log(this.titleForm.plantName);
        }
      });
    },
    /**
     * @description:  大棚信息带入
     * @param {*}
     */
    echoPlantingBatch() {
      //console.log(this.$attrs["productCycle"]);
      this.addAndEait = true;
      //console.log(this.$attrs["productCycle"]);
      let plantingBatchBean = this.$attrs["productCycle"].plantingBatchBean;
      //console.log(plantingBatchBean);
      this.titleForm = plantingBatchBean;
      this.titleForm.greenhouseId = plantingBatchBean.id;
      this.plantImg = this.$attrs[
        "productCycle"
      ].greenhousesBean.greenhousePhoto;
      this.titleForm.cameraIds = this.$attrs[
        "productCycle"
      ].greenhousesBean.camera;
      //console.log(this.titleForm.cameraIds);
      this.titleForm.weatherDeviceId = this.$attrs[
        "productCycle"
      ].greenhousesBean.meteorologicalEquipment;
      this.titleForm.soilDeviceId = this.$attrs[
        "productCycle"
      ].greenhousesBean.soilEquipment;
      this.titleForm.plantingArea = this.$attrs[
        "productCycle"
      ].greenhousesBean.areaInformation;
    },
    // 种植批次回显
    echoPlantingBatch2() {
      let plantingBatchBean = [];
      let greenhousesName = ''
      this.addAndEait = true;
      if (this.$attrs["nonBatch"] == "add") {
        plantingBatchBean = this.$attrs["productCycle"].greenhousesBean;
        this.plantImg = this.$attrs["productCycle"].greenhousesBean.greenhousePhoto;
        this.titleForm.cameraIds = this.$attrs["productCycle"].greenhousesBean.camera;
        this.titleForm = plantingBatchBean;
        greenhousesName = plantingBatchBean.greenhouseName; // 大棚名称
        this.titleForm.plotName = plantingBatchBean.plotName; // 地块名称
        this.titleForm.plantingArea = plantingBatchBean.areaInformation; // 面积信息
        this.titleForm.weatherDeviceId =plantingBatchBean.meteorologicalEquipment; // 气象设备
        this.titleForm.cameraIds = plantingBatchBean.camera; //监控设备
        this.titleForm.soilDeviceId = plantingBatchBean.soilEquipment;
        this.titleForm.greenhouseId = plantingBatchBean.id;
        //console.log(this.titleForm);
      } else if (this.$attrs["nonBatch"] == "eait") {
        //console.log(this.$attrs["productCycle"].plantingBatchBean);
        plantingBatchBean = this.$attrs["productCycle"].plantingBatchBean;
        this.plantImg = this.$attrs["productCycle"].plantingBatchBean.plantImg;
        let id = this.$attrs["productCycle"].plantingBatchBean.productName;
        this.cropNameChange(id);
        this.titleForm.plantName = id
        greenhousesName = plantingBatchBean.greenhousesName; // 大棚名称
        this.titleForm.plotName = plantingBatchBean.plotName; // 地块名称
        this.titleForm.plantingArea = plantingBatchBean.plantingArea; // 面积信息
        this.titleForm.weatherDeviceId = plantingBatchBean.weatherDeviceId; // 气象设备
        this.titleForm.cameraIds = plantingBatchBean.cameraIds; //监控设备
        this.titleForm.soilDeviceId = plantingBatchBean.soilEquipment;
        this.titleForm.greenhouseId = plantingBatchBean.id;
        this.titleForm = plantingBatchBean;
        //console.log(this.titleForm);
      }
      this.titleForm.greenhouseName = greenhousesName; 
    },

    clearList() {
      this.$emit("exits", this.addAndEait);
      // location.reload();
      // this.$router.go(0)
      this.$nextTick(() => {
        this.$refs["titleForm"].resetFields();
        this.plantImg = "";
        this.gowthStage[0].productCycleModelInfoBeanList = [
          {
            name: "",
            day: "",
            url: "",
            rempMin: "",
            rempMax: "",
          },
        ];
      });
    },
  },
};
</script>

<style scoped lang="less" src="../../style/public.less"></style>
<style lang="less" scoped>
@import "../../style/colorPublic";
.operationButton {
  text-align: right;
  position: absolute;
  top: 2vh;
  left: 70vh;
  width: 30%;
}

.butColor {
  background: @but_color;
}

.conter {
  margin-top: 2vh;
  display: flex;
  overflow: hidden;

  .essentialImg {
    width: 20vh;
    border: 1px solid #ccc;
    height: 20vh;
    margin-right: 3vh;
    border-radius: 5px;
  }
}

.essential {
  flex: 1;
  width: 30vw;
  //   border: 1px solid green;
  h3 {
    color: black;
    font-size: 2.1vh;
    font-weight: 500;
    margin: 1vh 0;
  }
}

.titleFormCon {
  display: flex;
  align-items: center;
  margin-bottom: 1vh;
}

.titleForm {
  display: flex;
  position: relative;
}

.batchBox {
  display: flex;
  position: relative;
  flex-wrap: wrap;
}

.grow {
  display: flex;
  justify-content: space-between;
}

.written {
  border: 0;
  outline: 0;
}

.growBut {
  height: 3vh;
}

// 流程信息
.gowthStageItemBox {
  margin: 1vh 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 35vw;
  //   max-height: 60vh;
  overflow: hidden;
  height: 30vh;
  overflow-y: auto;
}

.gowthStageItem {
  position: relative;
  border: 1px solid #ccc;
  width: 20vh;
  height: 37vh;
  margin: 0 2vh 2vh 0;
}

.imgUpload {
  width: 15vh;
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  margin: 2vh auto 1vh auto;
}

.imgUpload .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.imgUpload .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.imgUpload .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 15vh;
  line-height: 15vh;
  text-align: center;
}

.imgUpload .avatar {
  max-width: 15vh;
  max-height: 15vh;
  display: block;
}

.phase /deep/ .el-input__inner {
  width: 15vh;
  height: 4vh !important;
  line-height: 3vh !important;
}

.phase /deep/ .el-form-item {
  text-align: center;
  margin-bottom: 0;
}

.phase /deep/ .el-button--default {
  height: 3vh !important;
  padding: 9px 19px;
}

.addGowthStage {
  margin: 0 2vh;

  span {
    display: block;
    cursor: pointer;
    font-size: 6vh;
  }
}

.information {
  // height: 50vh;
  overflow: hidden;
  overflow-y: auto;
}

.proctImg {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.proctImg .avatar {
  max-width: 20vh;
  max-height: 20vh;
  display: block;
}

.proctImg .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 20vh;
  line-height: 20vh;
  text-align: center;
}

.plantingClassInput /deep/ .el-date-editor {
  width: 100%;
}

@media screen and (max-width: 1500px) {
  .growBut {
    height: 4vh;
  }

  .phase /deep/ .el-form-item__content {
    line-height: 27px;
  }

  .phase /deep/ .el-form-item {
    margin-bottom: 0 !important;
    text-align: center;
  }

  .gowthStageItemBox {
    height: 43vh;
  }
}

.public /deep/ .el-form-item {
  margin-bottom: 1.7vh !important;
}

.gang {
  width: 1vw;
  height: 2px;
  margin-left: 0.2vw;
  margin-right: 0.2vw;
  background-color: #ccc;
  display: inline-block;
}

.buttons {
  position: absolute;
  top: 10px;
  right: 5px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}

/deep/ input::-webkit-input-placeholder {
  font-size: 1.5vh;
}
</style>
